import React from 'react';
import * as ReactRedux from 'react-redux';

import type { IProduct } from 'types/entities';
import type { IProductReducerState } from 'types/redux';
import type { IResourceListInterface } from 'types/services';
import { api } from 'services/api';
import {
  setDialogClose,
  setDialogType,
  setListProducts,
  setProduct,
  setProductPagination,
  setProductState,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';

import { useAuthContext } from '../useAuthContext.hook';

interface IProductServiceGetAll {
  limit?: number;
  offset?: number;
}

interface IUseProductService {
  create: {
    description: IProduct['description'];
    image: File;
    name: IProduct['name'];
    status: IProduct['status'];
    internalMetadata: IProduct['internalMetadata'];
    metadata: Record<string, string | number> | null;
  };
  edit: {
    id: IProduct['id'];
    description: IProduct['description'];
    image: File | null;
    name: IProduct['name'];
    status: IProduct['status'];
    internalMetadata: IProduct['internalMetadata'];
    metadata: Record<string, string | number> | null;
  };
}

function useProductService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id: marketplaceId, id } = user;

  const getAll = React.useCallback(
    async (props?: IProductServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<IResourceListInterface<IProduct>>(
          `v2/marketplaces/${marketplaceId}/products`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: props?.limit,
              offset: props?.offset,
            },
          }
        );

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: products,
          total,
        } = response.data;

        const pagination: IProductReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListProducts(products),
          setProductState('success'),
          setProductPagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem dos produtos, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de produtos');
        throw error;
      }
    },
    [dispatch, marketplaceId]
  );

  const create = React.useCallback(
    async (params: IUseProductService['create']) => {
      try {
        const { description, image, name, status, internalMetadata, metadata } =
          params;

        const response = await api.post<IProduct>(
          `v2/marketplaces/${marketplaceId}/products`,
          {
            sellerId: id,
            description,
            name,
            status,
            internalMetadata,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        const product = response.data;

        const formData = new FormData();
        formData.append('file', image);

        await api.patch(
          `v2/marketplaces/${marketplaceId}/products/${product.id}/image`,
          formData,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        dispatch([
          setProductState('get'),
          setProduct(null),
          setSnackbarMessage('Produto criado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível criar este produto, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, id, marketplaceId]
  );

  const edit = React.useCallback(
    async (params: IUseProductService['edit']) => {
      try {
        const {
          id,
          description,
          image,
          name,
          status,
          internalMetadata,
          metadata,
        } = params;

        await api.put(
          `v2/marketplaces/${marketplaceId}/products/${id}`,
          {
            description,
            name,
            status,
            internalMetadata,
            metadata,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        );

        if (image) {
          const formData = new FormData();
          formData.append('file', image);

          await api.patch(
            `v2/marketplaces/${marketplaceId}/products/${id}/image`,
            formData,
            {
              auth: {
                username: process.env.REACT_APP_BYPASS_API_KEY!,
                password: '',
              },
            }
          );
        }

        dispatch([
          setProductState('get'),
          setProduct(null),
          setSnackbarMessage('Produto editado com sucesso!'),
          setSnackbarType('success'),
          setSnackbarOpen(),
          setDialogClose(),
          setDialogType(null),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível editar este produto, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
      }
    },
    [dispatch, marketplaceId]
  );

  return React.useMemo(
    () => ({
      getAll,
      create,
      edit,
    }),
    [getAll, create, edit]
  );
}

export default useProductService;
