import React from 'react';
import * as ReactRedux from 'react-redux';
import { validate } from 'validate.js';

import { getDisplayMoney, NumberUtils, PromiseUtils } from 'helpers';
import { useAuthContext } from 'hooks/useAuthContext.hook';
import * as CheckoutHooks from 'hooks/useCheckout';
import { api } from 'services/api';
import {
  setCheckout,
  setDialogClose,
  setDialogType,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { IOffer, IPlan, ITransaction } from 'types/entities';
import type { ICheckoutProduct } from 'types/entities/checkout';
import type { IResourceListInterface } from 'types/services';
import { CheckoutUtils } from 'helpers/checkout-utils';
// import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type TPaymentTypeAccepted = keyof Pick<
  IFormState['values'],
  'boleto' | 'credit' | 'pix'
>;

export interface IFormState {
  isValid: boolean;
  values: {
    name: string;
    general: string;
    boleto: {
      // isLateFeeActive: boolean;
      // isInterestActive: boolean;
      // isDiscountsActive: boolean;
      isActive: boolean;
      isInstallmentsEnabled: boolean;
      bodyInstructions: string | null;
      daysUntilExpiration: number;
      defaultInstallments: number;
      discount: string;
      discountType: 'percentage' | 'fixed';
      maxDaysToPay: number;
      maxInstallments: number;
      minInstallments: number;
      // pixAmount: number | null;
      // withPix: boolean;
    };
    pix: {
      isActive: boolean;
      isInstallmentsEnabled: boolean;
      defaultInstallments: number;
      discount: string;
      discountType: 'percentage' | 'fixed';
      maxInstallments: number;
      minInstallments: number;
    };
    credit: {
      isActive: boolean;
      isRecoverSaleEnabled: boolean;
      isInstallmentsEnabled: boolean;
      defaultInstallments: number;
      discount: string;
      discountType: 'percentage' | 'fixed';
      maxInstallments: number;
      minInstallments: number;
      recoverSaleAmount: number | null;
    };
    config: {
      coupon: {
        isEnabled: boolean;
        isRequired: boolean;
      };
    };
    offer: IOffer | null;
    plan: IPlan | null;
    planDueDate: string | null;
    checkoutProductType: ICheckoutProduct['type'];
    metadata: Record<string, string | number> | null;
  };
  errors: {
    name?: string;
    general?: string;
    boleto?: {
      // isLateFeeActive: boolean;
      // isInterestActive: boolean;
      // isDiscountsActive: boolean;
      isActive?: string;
      isInstallmentsEnabled?: string;
      bodyInstructions?: string;
      daysUntilExpiration?: string;
      defaultInstallments?: string;
      discount?: string;
      discountType?: string;
      maxDaysToPay?: string;
      maxInstallments?: string;
      minInstallments?: string;
      // pixAmount: number | null;
      // withPix: boolean;
    };
    pix?: {
      isActive?: string;
      isInstallmentsEnabled?: string;
      defaultInstallments?: string;
      discount?: string;
      discountType?: string;
      maxInstallments?: string;
      minInstallments?: string;
    };
    credit?: {
      isActive?: string;
      isRecoverSaleEnabled?: string;
      isInstallmentsEnabled?: string;
      defaultInstallments?: string;
      discount?: string;
      discountType?: string;
      maxInstallments?: string;
      minInstallments?: string;
      recoverSaleAmount?: string;
    };
    config?: {
      coupon: {
        isEnabled?: string;
        isRequired?: string;
      };
    };
    offer?: string;
    plan?: string;
    planDueDate?: string;
    checkoutProductType?: string;
    metadata?: string;
  };
  touched: {
    name?: boolean;
    general?: boolean;
    boleto?: {
      // isLateFeeActive: boolean;
      // isInterestActive: boolean;
      // isDiscountsActive: boolean;
      isActive?: boolean;
      isInstallmentsEnabled?: boolean;
      bodyInstructions?: boolean;
      daysUntilExpiration?: boolean;
      defaultInstallments?: boolean;
      discount?: boolean;
      discountType?: boolean;
      maxDaysToPay?: boolean;
      maxInstallments?: boolean;
      minInstallments?: boolean;
      // pixAmount: number | null;
      // withPix: boolean;
    };
    pix?: {
      isActive?: boolean;
      isInstallmentsEnabled?: boolean;
      defaultInstallments?: boolean;
      discount?: boolean;
      discountType?: boolean;
      maxInstallments?: boolean;
      minInstallments?: boolean;
    };
    credit?: {
      isActive?: boolean;
      isRecoverSaleEnabled?: boolean;
      isInstallmentsEnabled?: boolean;
      defaultInstallments?: boolean;
      discount?: boolean;
      discountType?: boolean;
      maxInstallments?: boolean;
      minInstallments?: boolean;
      recoverSaleAmount?: boolean;
    };
    config?: {
      coupon: {
        isEnabled?: boolean;
        isRequired?: boolean;
      };
    };
    offer?: boolean;
    plan?: boolean;
    planDueDate?: boolean;
    checkoutProductType?: boolean;
    metadata?: boolean;
  };
}

interface ILinkCopied {
  checkout: boolean;
  redeem: boolean;
}

const schema = {
  general: {
    presence: {
      allowEmpty: false,
      message: '^URL de redirecionamento é obrigatório',
    },
  },
};

export function useCheckoutEditDialog() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();
  const { marketplace_id } = user;

  const { state, service } = CheckoutHooks.useCheckout();

  const { checkout, state: checkoutState } = state;

  const [formState, setFormState] = React.useState<IFormState>({
    isValid: false,
    values: {
      name: checkout?.name || '',
      general: checkout?.redirectUrl.general || '',
      boleto: {
        isActive: checkout?.paymentTypesAccepted.includes('boleto') || false,
        // isLateFeeActive: false,
        // isInterestActive: false,
        // isDiscountsActive: false,
        bodyInstructions:
          checkout?.paymentTypesConfig.boleto.bodyInstructions || '',
        daysUntilExpiration:
          checkout?.paymentTypesConfig.boleto.daysUntilExpiration || 3,
        defaultInstallments:
          checkout?.paymentTypesConfig.boleto.defaultInstallments || 12,
        discount: CheckoutUtils.discountToLabel({
          type: checkout?.paymentTypesConfig.boleto.discountType,
          value: checkout?.paymentTypesConfig.boleto.discount,
        }),
        discountType:
          checkout?.paymentTypesConfig.boleto.discountType || 'fixed',
        isInstallmentsEnabled:
          checkout?.paymentTypesConfig.boleto.isInstallmentsEnabled || false,
        maxDaysToPay: checkout?.paymentTypesConfig.boleto.maxDaysToPay || 3,
        maxInstallments:
          checkout?.paymentTypesConfig.boleto.maxInstallments || 12,
        minInstallments:
          checkout?.paymentTypesConfig.boleto.minInstallments || 1,
      },
      pix: {
        isActive: checkout?.paymentTypesAccepted.includes('pix') || false,
        defaultInstallments:
          checkout?.paymentTypesConfig.pix.defaultInstallments || 12,
        discount: CheckoutUtils.discountToLabel({
          type: checkout?.paymentTypesConfig.pix.discountType,
          value: checkout?.paymentTypesConfig.pix.discount,
        }),
        discountType:
          checkout?.paymentTypesConfig.pix.discountType || 'percentage',
        isInstallmentsEnabled:
          checkout?.paymentTypesConfig.pix.isInstallmentsEnabled || false,
        maxInstallments: checkout?.paymentTypesConfig.pix.maxInstallments || 12,
        minInstallments: checkout?.paymentTypesConfig.pix.minInstallments || 1,
      },
      credit: {
        isActive: checkout?.paymentTypesAccepted.includes('credit') || false,
        defaultInstallments:
          checkout?.paymentTypesConfig.credit.defaultInstallments || 12,
        discount: CheckoutUtils.discountToLabel({
          type: checkout?.paymentTypesConfig.credit.discountType,
          value: checkout?.paymentTypesConfig.credit.discount,
        }),
        discountType:
          checkout?.paymentTypesConfig.credit.discountType || 'percentage',
        isInstallmentsEnabled:
          checkout?.paymentTypesConfig.credit.isInstallmentsEnabled || false,
        isRecoverSaleEnabled:
          checkout?.paymentTypesConfig.credit.isRecoverSaleEnabled || false,
        maxInstallments:
          checkout?.paymentTypesConfig.credit.maxInstallments || 12,
        minInstallments:
          checkout?.paymentTypesConfig.credit.minInstallments || 1,
        recoverSaleAmount:
          (checkout?.paymentTypesConfig.credit.recoverSaleAmount || 0) / 100,
      },
      config: {
        coupon: {
          isEnabled: checkout?.config.coupon.isEnabled || false,
          isRequired: checkout?.config.coupon.isRequired || false,
        },
      },
      offer: null,
      plan: null,
      planDueDate: null,
      checkoutProductType: 'product',
      metadata: null,
    },
    errors: {},
    touched: {
      checkoutProductType: true,
      plan: true,
      offer: true,
      boleto: {
        discount: true,
      },
      pix: {
        discount: true,
      },
      credit: {
        discount: true,
      },
    },
  });
  const [plans, setPlans] = React.useState<IPlan[]>([]);
  const [offers, setOffers] = React.useState<IOffer[]>([]);
  const [linkCopied, setLinkCopied] = React.useState<ILinkCopied>({
    checkout: false,
    redeem: false,
  });

  const handleFormChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      }));
    },
    []
  );

  const handleProductChange = React.useCallback(
    (event: React.ChangeEvent<object>, plan: IPlan | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          plan,
        },
      }));
    },
    []
  );

  const handleBoletoChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const inputNameBoleto = event.target.name.replace('boleto-', '');

      let value: string | boolean = event.target.value;

      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }

      setFormState(formState => {
        return {
          ...formState,
          values: {
            ...formState.values,
            boleto: {
              ...formState.values.boleto,
              [inputNameBoleto]:
                event.target.type === 'checkbox' ? event.target.checked : value,
              discount:
                inputNameBoleto === 'discountType'
                  ? value === 'percentage'
                    ? '% 0'
                    : 'R$ 0'
                  : formState.values.boleto.discount,
            },
          },
          touched: {
            ...formState.touched,
            boleto: {
              ...formState.touched.boleto,
              [inputNameBoleto]: true,
            },
          },
        };
      });
    },
    []
  );

  const handlePixChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const inputNamePix = event.target.name.replace('pix-', '');

      let value: string | boolean = event.target.value;

      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          pix: {
            ...formState.values.pix,
            [inputNamePix]:
              event.target.type === 'checkbox' ? event.target.checked : value,
            discount:
              inputNamePix === 'discountType'
                ? value === 'percentage'
                  ? '% 0'
                  : 'R$ 0'
                : formState.values.pix.discount,
          },
        },
        touched: {
          ...formState.touched,
          pix: {
            ...formState.touched.pix,
            [inputNamePix]: true,
          },
        },
      }));
    },
    []
  );

  const handleCreditChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const inputNameCredit = event.target.name.replace('credit-', '');

      let value: string | boolean = event.target.value;

      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          credit: {
            ...formState.values.credit,
            [inputNameCredit]:
              event.target.type === 'checkbox' ? event.target.checked : value,
            discount:
              inputNameCredit === 'discountType'
                ? value === 'percentage'
                  ? '% 0'
                  : 'R$ 0'
                : formState.values.credit.discount,
          },
        },
        touched: {
          ...formState.touched,
          credit: {
            ...formState.touched.credit,
            [inputNameCredit]: true,
          },
        },
      }));
    },
    []
  );

  const handleDiscountValueChange = React.useCallback(
    (
      paymentType: ITransaction['payment_type'],
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      let value = event.target.value;

      if (formState.values[paymentType].discountType === 'percentage') {
        value = value.toString().replace(/[^0-9]/g, '');

        if (Number(value) > 100) {
          value = '100';
        }

        value = `% ${Number(value)}`;
      }

      if (formState.values[paymentType].discountType === 'fixed') {
        const valueNumber = Number(value);

        if (valueNumber > Number.MAX_SAFE_INTEGER) {
          value = Number.MAX_SAFE_INTEGER.toString();
        }

        value = getDisplayMoney(valueNumber);
      }

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          [paymentType]: {
            ...prevState.values[paymentType],
            discount: value,
          },
        },
      }));
    },
    [formState.values]
  );

  const handleOfferChange = React.useCallback(
    (event: React.ChangeEvent<object>, offer: IOffer | null) => {
      event.persist();

      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          offer,
        },
      }));
    },
    []
  );

  const handleMetadataChange = React.useCallback(
    (metadata: Record<string, string | number> | null) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          metadata,
        },
      }));
    },
    []
  );

  const hasError = React.useCallback(
    (field: keyof IFormState['values']) => {
      return !!formState.touched[field] && !!formState.errors[field];
    },
    [formState.errors, formState.touched]
  );

  const hasBoletoError = React.useCallback(
    (field: keyof IFormState['values']['boleto']) => {
      return (
        !!formState.touched.boleto?.[field] &&
        !!formState.errors.boleto?.[field]
      );
    },
    [formState.errors, formState.touched]
  );

  const hasPixError = React.useCallback(
    (field: keyof IFormState['values']['pix']) => {
      return (
        !!formState.touched.pix?.[field] && !!formState.errors.pix?.[field]
      );
    },
    [formState.errors, formState.touched]
  );

  const hasCreditError = React.useCallback(
    (field: keyof IFormState['values']['credit']) => {
      return (
        !!formState.touched.credit?.[field] &&
        !!formState.errors.credit?.[field]
      );
    },
    [formState.errors, formState.touched]
  );

  function handleInputFocus(inputName: string) {
    const inputElement = document.querySelector(
      `#${inputName}`
    ) as HTMLInputElement;
    inputElement.focus();
  }

  const getDiscountValue = React.useCallback(
    (discountType: 'percentage' | 'fixed', discount: string): number => {
      if (discountType === 'percentage') {
        return Number(discount.replace(/[^0-9]/g, '')) / 100;
      }

      return NumberUtils.toPrecision(
        Number(discount.replace(/[^0-9]/g, '')),
        0
      );
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setFormState(prevState => ({
      ...prevState,
      errors: {},
    }));

    const {
      boleto,
      checkoutProductType,
      config,
      credit,
      metadata,
      offer,
      pix,
      plan,
      planDueDate,
    } = formState.values;
    let { name, general } = formState.values;

    name = name.trim();
    general = general.trim();

    function setPaymentTypeError(
      paymentType: ITransaction['payment_type'],
      field: string,
      message: string
    ) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [paymentType]: {
            ...prevState.errors[paymentType],
            [field]: message,
          },
        },
      }));
    }

    if (!boleto.isActive && !pix.isActive && !credit.isActive) {
      setPaymentTypeError(
        'boleto',
        'isActive',
        'É necessário preencher um método de pagamento'
      );

      return;
    }

    if (general === '') {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          general: 'URL de redirecionamento é obrigatório',
        },
      }));

      handleInputFocus('general');
      return;
    }

    if (checkoutProductType === 'plan' && !plan) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          plan: 'Selecione um plano',
        },
      }));

      handleInputFocus('plan');
      return;
    }

    if (checkoutProductType === 'product' && !offer) {
      setFormState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          offer: 'Selecione uma oferta',
        },
      }));

      handleInputFocus('offer');
      return;
    }

    const paymentTypesAccepted: TPaymentTypeAccepted[] = [];

    if (boleto.isActive) {
      paymentTypesAccepted.push('boleto');
    }

    if (credit.isActive) {
      paymentTypesAccepted.push('credit');
    }

    if (pix.isActive) {
      paymentTypesAccepted.push('pix');
    }

    const boletoDiscount = getDiscountValue(
      boleto.discountType,
      boleto.discount
    );

    const pixDiscount = getDiscountValue(pix.discountType, pix.discount);

    const creditDiscount = getDiscountValue(
      credit.discountType,
      credit.discount
    );

    if (checkoutState === 'create') {
      await service.create({
        name,
        offerId:
          formState.values.checkoutProductType === 'product' ? offer!.id : null,
        general,
        paymentTypesAccepted,
        paymentTypesConfig: {
          boleto: {
            bodyInstructions: boleto.bodyInstructions || null,
            daysUntilExpiration: Number(boleto.daysUntilExpiration),
            defaultInstallments: Number(boleto.defaultInstallments),
            discount: boletoDiscount,
            discountType: boleto.discountType,
            isInstallmentsEnabled: boleto.isInstallmentsEnabled,
            maxDaysToPay: Number(boleto.maxDaysToPay),
            maxInstallments: Number(boleto.maxInstallments),
            minInstallments: Number(boleto.minInstallments),
          },
          pix: {
            defaultInstallments: Number(pix.defaultInstallments),
            discount: pixDiscount,
            discountType: pix.discountType,
            isInstallmentsEnabled: pix.isInstallmentsEnabled,
            maxInstallments: Number(pix.maxInstallments),
            minInstallments: Number(pix.minInstallments),
          },
          credit: {
            defaultInstallments: Number(credit.defaultInstallments),
            discount: creditDiscount,
            discountType: credit.discountType,
            isInstallmentsEnabled: credit.isInstallmentsEnabled,
            isRecoverSaleEnabled: credit.isRecoverSaleEnabled,
            maxInstallments: Number(credit.maxInstallments),
            minInstallments: Number(credit.minInstallments),
            recoverSaleAmount: credit.recoverSaleAmount
              ? NumberUtils.toPrecision(credit.recoverSaleAmount * 100, 0)
              : null,
          },
        },
        config,
        checkoutProductType,
        productId:
          formState.values.checkoutProductType === 'plan'
            ? plan!.id
            : offer!.productId,
        metadata,
        internalMetadata: {
          planDueDate: Number(planDueDate),
        },
      });
    }

    if (checkoutState === 'edit') {
      await service.edit({
        name,
        checkoutId: checkout!.id,
        general,
        metadata,
        paymentTypesAccepted,
        paymentTypesConfig: {
          boleto: {
            bodyInstructions: boleto.bodyInstructions || null,
            daysUntilExpiration: Number(boleto.daysUntilExpiration),
            defaultInstallments: Number(boleto.defaultInstallments),
            discount: boletoDiscount,
            discountType: boleto.discountType,
            isInstallmentsEnabled: boleto.isInstallmentsEnabled,
            maxDaysToPay: Number(boleto.maxDaysToPay),
            maxInstallments: Number(boleto.maxInstallments),
            minInstallments: Number(boleto.minInstallments),
          },
          pix: {
            defaultInstallments: Number(pix.defaultInstallments),
            discount: pixDiscount,
            discountType: pix.discountType,
            isInstallmentsEnabled: pix.isInstallmentsEnabled,
            maxInstallments: Number(pix.maxInstallments),
            minInstallments: Number(pix.minInstallments),
          },
          credit: {
            defaultInstallments: Number(credit.defaultInstallments),
            discount: creditDiscount,
            discountType: credit.discountType,
            isInstallmentsEnabled: credit.isInstallmentsEnabled,
            isRecoverSaleEnabled: credit.isRecoverSaleEnabled,
            maxInstallments: Number(credit.maxInstallments),
            minInstallments: Number(credit.minInstallments),
            recoverSaleAmount: credit.recoverSaleAmount
              ? NumberUtils.toPrecision(credit.recoverSaleAmount * 100, 0)
              : null,
          },
        },
        config,
        internalMetadata: {
          planDueDate: Number(planDueDate),
        },
      });
    }
  }, [checkout, checkoutState, formState.values, service, getDiscountValue]);

  const handleDialogClose = React.useCallback(() => {
    dispatch([setCheckout(null), setDialogClose(), setDialogType(null)]);
  }, [dispatch]);

  const handleCheckboxChange = React.useCallback(
    (inputName: TPaymentTypeAccepted) => {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          [inputName]: {
            ...prevState.values[inputName],
            isActive: !prevState.values[inputName].isActive,
          },
        },
        touched: {
          ...prevState.touched,
          [inputName]: {
            ...prevState.touched[inputName],
            isActive: true,
          },
        },
      }));
    },
    []
  );

  const handleConfigChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      const inputNameConfig = event.target.name.split('-');

      const configKeyName =
        inputNameConfig[1] as keyof IFormState['values']['config'];
      const configPropertyName = inputNameConfig[2];

      if (configKeyName === undefined || configPropertyName === undefined) {
        return;
      }

      let value: string | boolean = event.target.value;

      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          config: {
            ...formState.values.config,
            [configKeyName]: {
              ...formState.values.config[configKeyName],
              [configPropertyName]:
                event.target.type === 'checkbox' ? event.target.checked : value,
            },
          },
        },
        touched: {
          ...formState.touched,
          config: {
            ...formState.touched.config,
            [configKeyName]: {
              ...formState.touched.config?.[configKeyName],
              [configPropertyName]: true,
            },
          },
        },
      }));
    },
    []
  );

  const handleSelectAllText = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      try {
        const inputElement = (
          event as unknown as React.ChangeEvent<HTMLInputElement>
        ).target;

        inputElement.select();
        inputElement.setSelectionRange(0, 99999);
      } catch (error) {
        console.error('Não foi possível copiar o texto');
      }
    },
    []
  );

  const handleCopyToClipBoard = React.useCallback(
    async (linkType: keyof ILinkCopied) => {
      let inputElement: HTMLInputElement;

      if (linkType === 'checkout') {
        inputElement = document.querySelector(
          '#checkout-link'
        ) as HTMLInputElement;
      }

      if (linkType === 'redeem') {
        inputElement = document.querySelector(
          '#redeem-link'
        ) as HTMLInputElement;
      }

      if (!inputElement!) {
        return;
      }

      inputElement.select();
      inputElement.setSelectionRange(0, 99999);
      await navigator.clipboard.writeText(inputElement.value);

      setLinkCopied(prevState => ({
        ...prevState,
        [linkType]: true,
      }));

      dispatch([
        setSnackbarType('info'),
        setSnackbarMessage('Copiado para área de transferência'),
        setSnackbarOpen(),
      ]);

      await PromiseUtils.awaiter(3000);

      setLinkCopied(prevState => ({
        ...prevState,
        [linkType]: false,
      }));
    },
    [dispatch]
  );

  // const handleDateChange = React.useCallback(
  //   (
  //       field: keyof Pick<
  //         IFormState['values']['boleto']['lateFee'],
  //         'startDate'
  //       >
  //     ) =>
  //     (date: MaterialUiPickersDate) => {
  //       setFormState(prevState => ({
  //         ...prevState,
  //         values: {
  //           ...prevState.values,
  //           [field]: date,
  //           boleto: {
  //             ...prevState.values.boleto,
  //             lateFee: {
  //               ...prevState.values.boleto.lateFee,
  //               [field]: date?.toISOString() || null,
  //             },
  //           },
  //         },
  //       }));
  //     },
  //   []
  // );

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  React.useEffect(() => {
    if (
      checkoutState === 'create' &&
      formState.values.checkoutProductType === 'plan'
    ) {
      api
        .get<IResourceListInterface<IPlan>>(
          `v2/marketplaces/${marketplace_id}/plans`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
            params: {
              limit: 1000,
              offset: 0,
            },
          }
        )
        .then(response => {
          const result = response.data;

          setPlans(result.items);
        })
        .catch(error => {
          console.error(error);
        });
    }

    if (
      checkoutState === 'create' &&
      formState.values.checkoutProductType === 'product'
    ) {
      api
        .get<IResourceListInterface<IOffer>>(
          `v2/marketplaces/${marketplace_id}/offers`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
        .then(response => {
          const result = response.data;

          setOffers(result.items);
        })
        .catch(error => {
          console.error(error);
        });
    }

    if (checkoutState === 'edit') {
      api
        .get<IResourceListInterface<ICheckoutProduct>>(
          `v2/marketplaces/${marketplace_id}/checkouts/${
            checkout!.id
          }/products`,
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
        .then(response => {
          const result = response.data;

          if (result.items.length === 0) {
            return;
          }

          const checkoutProduct = result.items[0];

          if (checkoutProduct.type === 'product') {
            api
              .get<IOffer>(
                `v2/marketplaces/${marketplace_id}/offers/${checkoutProduct.offerId}`,
                {
                  auth: {
                    username: process.env.REACT_APP_BYPASS_API_KEY!,
                    password: '',
                  },
                  params: {
                    limit: 1000,
                    offset: 0,
                  },
                }
              )
              .then(response => {
                const offer = response.data;

                setOffers([offer]);
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    offer,
                    checkoutProductType: checkoutProduct.type,
                  },
                }));
              })
              .catch(error => {
                console.error(error);
              });
          }

          if (checkoutProduct.type === 'plan') {
            api
              .get<IPlan>(
                `v2/marketplaces/${marketplace_id}/plans/${checkoutProduct.productId}`,
                {
                  auth: {
                    username: process.env.REACT_APP_BYPASS_API_KEY!,
                    password: '',
                  },
                  params: {
                    limit: 1000,
                    offset: 0,
                  },
                }
              )
              .then(response => {
                const plan = response.data;

                setPlans([plan]);
                setFormState(prevState => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    plan,
                    checkoutProductType: checkoutProduct.type,
                    planDueDate:
                      checkout?.internalMetadata?.planDueDate?.toString() ||
                      null,
                  },
                }));
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [
    checkout,
    checkoutState,
    formState.values.checkoutProductType,
    marketplace_id,
  ]);

  return React.useMemo(
    () => ({
      checkout,
      checkoutState,
      formState,
      linkCopied,
      offers,
      plans,
      // handleDateChange,
      handleBoletoChange,
      handleCheckboxChange,
      handleConfigChange,
      handleCopyToClipBoard,
      handleCreditChange,
      handleDialogClose,
      handleDiscountValueChange,
      handleFormChange,
      handleMetadataChange,
      handleOfferChange,
      handlePixChange,
      handleProductChange,
      handleSelectAllText,
      handleSubmit,
      hasBoletoError,
      hasCreditError,
      hasError,
      hasPixError,
      setFormState,
    }),
    [
      checkout,
      checkoutState,
      formState,
      hasBoletoError,
      hasCreditError,
      hasError,
      hasPixError,
      linkCopied,
      offers,
      plans,
      // handleDateChange,
      handleBoletoChange,
      handleCheckboxChange,
      handleConfigChange,
      handleCopyToClipBoard,
      handleCreditChange,
      handleDialogClose,
      handleDiscountValueChange,
      handleFormChange,
      handleMetadataChange,
      handleOfferChange,
      handlePixChange,
      handleProductChange,
      handleSelectAllText,
      handleSubmit,
      setFormState,
    ]
  );
}
