import React from 'react';
import * as ReactRedux from 'react-redux';

import { api } from 'services/api';
import {
  setCustomerState,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarType,
} from 'store/actions';
import type { IResourceListInterface } from 'types/services';

import { useAuthContext } from '../useAuthContext.hook';

import {
  setChargePagination,
  setChargeState,
  setListCharges,
} from 'store/actions/charge-actions';
import type { IChargeReducerState } from 'types/redux/reducers/charge-reducer.types';
import type { IHttpPopulateCharge } from 'types/entities/charge';

interface IChargeServiceGetAll {
  limit?: number;
  offset?: number;
}

function useChargeService() {
  const dispatch = ReactRedux.useDispatch();

  const { user } = useAuthContext();

  const { marketplace_id } = user;

  const getAll = React.useCallback(
    async (props?: IChargeServiceGetAll): Promise<void> => {
      try {
        const response = await api.get<
          IResourceListInterface<IHttpPopulateCharge>
        >(`v2/marketplaces/${marketplace_id}/charges`, {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
          params: {
            limit: props?.limit,
            offset: props?.offset,
            populate: 'buyer',
          },
        });

        const {
          page,
          offset,
          limit,
          has_more: hasMore,
          items: charges,
          total,
        } = response.data;

        const pagination: IChargeReducerState['pagination'] = {
          hasMore,
          limit,
          offset,
          page,
          total,
        };

        dispatch([
          setListCharges(charges),
          setChargeState('success'),
          setChargePagination(pagination),
        ]);
      } catch (error) {
        dispatch([
          setSnackbarMessage(
            'Não foi possível fazer a listagem das cobranças, tente novamente mais tarde!'
          ),
          setSnackbarType('error'),
          setSnackbarOpen(),
        ]);
        console.error('Não foi possível buscar a lista de cobranças.');
        throw error;
      }
    },
    [dispatch, marketplace_id]
  );

  const report = React.useCallback(async () => {
    try {
      const response = await api.get(
        `v2/marketplaces/${marketplace_id}/charges/report`,
        {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
          responseType: 'blob',
        }
      );

      const blob = response.data;
      const linkElement = document.createElement('a');
      linkElement.href = URL.createObjectURL(blob);
      linkElement.download = 'Charges.csv';
      linkElement.click();
      setTimeout(() => URL.revokeObjectURL(linkElement.href), 0);
    } catch (error) {
      dispatch([
        setSnackbarMessage(
          'Não foi possível gerar o relatório de cobranças, tente novamente mais tarde!'
        ),
        setSnackbarType('error'),
        setSnackbarOpen(),
        setCustomerState('failed'),
      ]);
      console.error('Não foi possível gerar o relatório de cobranças.');
      throw error;
    }
  }, [dispatch, marketplace_id]);

  return React.useMemo(
    () => ({
      getAll,
      report,
    }),
    [getAll, report]
  );
}

export default useChargeService;
